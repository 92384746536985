import React, { useEffect, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { HamburgerMenu } from "../HamburgerMenu";
import { NavigationDropdown } from "../NavigationDropdown";

const NavBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  isolation: isolate;
  box-sizing: border-box;
  padding: 0.4rem;

  position: fixed;
  width: 100%;
  height: 4rem;
  left: 0px;
  top: 0px;

  background: linear-gradient(
    180deg,
    rgba(224, 0, 0, 0.5) 0%,
    rgba(255, 131, 104, 0.5) 100%
  );
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.35);
  backdrop-filter: blur(2rem);
  border-radius: 0px 0px 1rem 1rem;

  z-index: 1000;
`;

const TopText = styled(Link)`
  font-family: "Odibee Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 3rem;
  line-height: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  text-decoration: none;
  color: #fff;

  text-shadow: 0px 1px 8px rgba(0, 0, 0, 1);

  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;

  z-index: 1001;
`;

const Logo = styled.img`
  filter: drop-shadow(0px 0px 4px rgba(255, 255, 255, 0.5));
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
`;

const LogoButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  display: flex;
  border-radius: 2rem;
  cursor: pointer;

  z-index: 1002;
  transition: transform 0.2s;

  &:hover {
    transform: rotate(45deg);
  }

  &:focus {
    transform: rotate(45deg);
  }

  &:active {
    transition: transform 0.1s;
    transform: rotate(-90deg);
  }
`;

const Header: React.FC = () => {
  const audio = useMemo(() => new Audio("assets/taustamusiikki.mp3"), []);
  const [play, setPlay] = useState(true);
  const [showNavigation, setShowNavigation] = useState(false);

  useEffect(() => {
    audio.addEventListener("ended", () => setPlay(false));
    return () => {
      audio.removeEventListener("ended", () => setPlay(false));
    };
  }, []);

  const onShowClick = () => {
    setShowNavigation(!showNavigation);
  };

  const togglePlay = () => {
    setPlay(!play);
    play ? audio.play() : audio.pause();
  };

  return (
    <>
      <NavBar id="headerRoot">
        <LogoButton onClick={togglePlay} id="headerMusicButton" tabIndex={0}>
          <Logo
            src="assets/ratti.webp"
            draggable="false"
            width="48"
            height="48"
            alt="racing_wheel"
          />
        </LogoButton>
        <TopText to="/" draggable="false" id="headerText" translate="no">
          SimRacingFans
        </TopText>
        <HamburgerMenu
          onClick={onShowClick}
          isActive={showNavigation}
          id="navigation"
        />
      </NavBar>
      <NavigationDropdown
        id="navigationDropdown"
        isVisible={showNavigation}
        onClose={() => setShowNavigation(false)}
      />
    </>
  );
};

export { Header };
