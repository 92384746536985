import React from "react";
import { BigText, ContentWrapper, Spacer, TextContainer } from "../components";

const Simulators = () => {
  return (
    <>
      <BigText text="Simulaattorit" id="simulatorsIntroBig" spacing="40vh" />
      <ContentWrapper id="simulatorsIntroContent">
        <TextContainer header="Suositut simulaattorit" id="simulatorsIntro">
          <p>
            Simulaattoreita on tätä nykyä tarjolla paljon ja laidasta laitaan.
            Simulaattoreita löytyy niin driftaamiseen, romuralliin, rentoon
            vähemmän realistiseen kasuaaliin kruisailuun, kuin myös äärimmäiseen
            realismiin. Oma mielikuvitus on rajana.
          </p>
          <p>
            Monet simulaattorit tukevat myös yhteisön luomaa sisältöä, kuten
            grafiikkamodeja, ajoneuvoja, karttoja, kilpailujen ”prefixejä”, kuin
            myös täysin erilaisia fysiikoita. Nämä ovat kuitenkin täysin
            valinnaisia lisiä, joihin varsinkaan aivan alkuvaiheessa ja vähemmän
            asiaan perehtyneenä ei kannata keskittää liikaa huomiota.
          </p>
          <p>
            Tämä saattaa nopeasti kuulostaa hyvin pitkältä ja kompleksilta
            listalta asioita, josta voi muodostua suuri valinnanvaikeus, mutta
            ei hätää. Olen koostanut tähän yleisimmät simulaattorit, listannut
            niistä muutamia seikkoja, joista ne ovat tunnettuja ja myös oma
            näkemykseni siitä, minkälaiselle ajajalle itse kukin niistä on
            sopiva.
          </p>
        </TextContainer>
        <Spacer height="10vh" mobileHeight="50vh" id="simulatorsFirstSpacer" />
        <TextContainer header="Assetto Corsa" id="simulatorsAC">
          <p>
            Assetto Corsa on suosittu kilpasimulaattori, joka on saavuttanut
            suuren suosion simracerien keskuudessa realistisuuden ja
            yksityiskohtien huomioimisen ansiosta. Peli sisältää laajan
            valikoiman autoja ja ratoja, mukaan lukien sekä moderneja että
            klassisia kilpa-autoja sekä maantieautoja.
          </p>
          <p>
            Yksi Assetto Corsan tärkeimmistä myyntivalteista on sen
            edistyksellinen fysiikkamoottori, joka tarjoaa äärimmäisen
            realistisen ajokokemuksen tarkalla rengas- ja jousitusmallinnus sekä
            realistiset vauriot ja kuluminen. Vauriot ovat pääasiassa ajoon
            vaikuttavia, eivät kosmeettisia tai pehmeärunkosimulaattorin
            tapaisia. Assetto Corsa tukee myös modeja, joiden avulla pelaajat
            voivat lisätä peliin uusia autoja, raitoja ja muuta sisältöä.
          </p>
          <p>
            Pelin moninpelitila on suosittu myös simracerien keskuudessa, koska
            sen avulla pelaajat voivat kilpailla toisiaan vastaan järjestetyissä
            tapahtumissa tai satunnaisissa kilpailuissa. Lisäksi moninpelissä on
            olemassa myös kruisailuyhteisöjä, jotka vain kruisailevat milloin
            missäkin, kaupungeissa tai vuoristoilla erilaisilla autoilla,
            yleensä katukulttuurissa suosituilla. Lisäksi Assetto Corsassa on
            valikoima vaihtoehtoja pelin vaikeuden ja realistisuuden
            säätämiseksi kaiken tasoisille pelaajille.
          </p>
          <p>
            Kaiken kaikkiaan Assetto Corsa on arvostettu simracing-peli, joka
            tarjoaa laajan valikoiman vaihtoehtoja pelaajille, jotka etsivät
            realistista ja haastavaa kilpa-kokemusta. Peli on täydellinen niin
            kokeneelle kuin aloittelijallekin, eikä maksa paljoa varsinkaan
            alennuksissa. Peli on saatavilla niin tietokoneelle (Windows), kuin
            myös PS4:lle ja Xbox Onelle.
          </p>
          <h3>Pelin hyvät puolet</h3>
          <ul>
            <li>Monipuoliset säädöt</li>
            <li>Realistiset fysiikat</li>
            <li>Modaustuki</li>
            <li>Sisäänrakennettu VR ja moninpelituki</li>
            <li>Halpa hinta</li>
            <li>Lisenssoidut autot</li>
            <li>
              API joka mahdollistaa kustomoidun laitteiston tuen toteuttamisen
            </li>
          </ul>
          <h3>Pelin huonot puolet</h3>
          <ul>
            <li>Melko vanha</li>
            <li>Välillä epävakaa</li>
            <li>Huonot sorafysiikat</li>
            <li>
              Vaikka auto voi vaurioitua, ei vauriot ole ulkonäöllisesti
              realistisia
            </li>
            <li>Grafiikat vanhettuneet (korjattavissa modeilla)</li>
          </ul>
          <h3>Kenelle peli sopii</h3>
          <ul>
            <li>Kilpa-ajajille, jotka hakevat realistista tuntumaa</li>
            <li>Driftaajille</li>
            <li>Kruisailijoille</li>
          </ul>
          <img
            id="assettoCorsaLogo"
            src="assets/suositutSimulaattorit/assettocorsa.webp"
            alt="assetto_corsa_logo"
            width="80%"
          />
          <small>Assetto Corsan tunnusmainen kuva</small>
        </TextContainer>
        <Spacer height="10vh" mobileHeight="50vh" id="simulatorsSecondSpacer" />
        <TextContainer header="BeamNG.drive" id="simulatorsBeamNG">
          <p>
            BeamNG.drive on suosittu ajoneuvosimulaatiopeli, joka tarjoaa
            erittäin yksityiskohtaisen ja realistisen ajokokemuksen. Yksi
            BeamNG.driven tärkeimmistä myyntivalteista on sen kehittynyt
            pehmeärunkoinen fysiikkamoottori, joka simuloi ajoneuvojen ja niiden
            osien käyttäytymistä erittäin realistisella tavalla. Tämä antaa
            pelaajille mahdollisuuden kokea ajoneuvon koko dynamiikka
            jousituksesta ja ohjauksesta moottorin ja voimansiirron
            käyttäytymiseen.
          </p>
          <p>
            Realistisen fysiikkamoottorin lisäksi BeamNG.drive sisältää laajan
            valikoiman ajoneuvoja, kuten autoja, kuorma-autoja ja jopa busseja,
            sekä erilaisia karttoja ja ympäristöjä tutkittavaksi. Peli tukee
            myös modeja, joiden avulla pelaajat voivat lisätä peliin uusia
            ajoneuvoja, karttoja ja muuta sisältöä. BeamNG.driven uratila
            tarjoaa pelaajille erilaisia haasteita ja tehtäviä suoritettaviksi,
            mukaan lukien ajotestit, toimitustehtävät ja kilpatapahtumat.
          </p>
          <p>
            Kaiken kaikkiaan BeamNG.drive tarjoaa erittäin mukaansatempaavan ja
            realistisen ajoneuvosimulaatiokokemuksen, joka on suosittu pelaajien
            keskuudessa, jotka etsivät yksityiskohtaista ja haastavaa ajopeliä.
            Peli on saatavilla vain tietokoneelle (Windows).
          </p>
          <h3>Pelin hyvät puolet</h3>
          <ul>
            <li>
              Pehmeärunkoinen fysiikkamoottori, realistinen tuntuma ja
              vaurioituminen
            </li>
            <li>Tuunausmahdollisuudet</li>
            <li>Modituki, sisäänrakennettu modirepository</li>
            <li>Haasteet</li>
            <li>Avoin maailma</li>
          </ul>
          <h3>Pelin huonot puolet</h3>
          <ul>
            <li>Vaatii tehokkaan tietokoneen</li>
            <li>UI bugittaa satunnaisesti</li>
            <li>Ei merkkiautoja</li>
            <li>Ei VR-tukea</li>
            <li>Ei sisäänrakennettua moninpelitilaa, saatavilla modeilla</li>
          </ul>
          <h3>Kenelle peli sopii</h3>
          <ul>
            <li>
              Äärimmäistä ajokokemusta havittelevaa, joka haluaa tuntea
              pienimmätkin auton elementtien vaikutukset ajamisessa
            </li>
            <li>Romurallin ajajalle</li>
            <li>
              Tutkiville ajajille, jotka haluavat ajaa erilaisissa olosuhteissa
            </li>
          </ul>
          <img
            id="beamNGDriveLogo"
            src="assets/suositutSimulaattorit/beamngdrive.jpg"
            alt="beamng_drive_logo"
            width="80%"
          />
          <small>BeamNG.Driven logo</small>
        </TextContainer>
        <Spacer height="10vh" mobileHeight="50vh" id="simulatorsThirdSpacer" />
        <TextContainer header="iRacing" id="simulatorsIRacing">
          <p>
            iRacing on suosittu online-ajo-simulaattori, josta on tullut monien
            ammattilaisten simracerien ja moottoriurheilujoukkueiden suosikki.
            Peli sisältää laajan valikoiman virallisesti lisensoituja autoja ja
            ratoja, mukaan lukien monet maailman kuuluisimmista kilparadoista ja
            -sarjoista.
          </p>
          <p>
            Yksi iRacingin tärkeimmistä myyntivalteista on sen edistynyt
            fysiikkamoottori, joka tarjoaa äärimmäisen realistisen ajokokemuksen
            tarkalla rengas- ja jousitusmallinnus sekä realistiset vauriot ja
            kulumiset. Vauriot eivät kuitenkaan ole pehmeärunkosimulaattorin
            kaltaiset.
          </p>
          <p>
            Pelin online-moninpelitila on suuri veto simracereille, koska sen
            avulla pelaajat voivat kilpailla toisiaan vastaan järjestetyissä
            tapahtumissa ja liigoissa. iRacingissa on myös hienostunut
            matchmaking- ja luokitusjärjestelmä, joka varmistaa reilun ja
            kilpailukykyisen kilpailun.
            <br />
            Pelin tilauspohjainen malli voi olla este joillekin pelaajille,
            mutta se varmistaa myös sen, että peliä päivitetään jatkuvasti ja
            ylläpidetään uudella sisällöllä ja ominaisuuksilla.
          </p>
          <p>
            Kaiken kaikkiaan iRacing on arvostettu simracing-peli, joka tarjoaa
            realistisen ja haastavan kilpa-kokemuksen pelaajille, jotka etsivät
            huippua virtuaalisesta moottoriurheilusta. Peli on saatavilla vain
            tietokoneelle (Windows).
          </p>
          <h3>Pelin hyvät puolet</h3>
          <ul>
            <li>Realistinen kilpa-ajo</li>
            <li>Sisäänrakennettu moninpeli</li>
            <li>Matchmaking -järjestelmä</li>
            <li>Lisenssoidut autot</li>
            <li>VR-tuki</li>
          </ul>
          <h3>Pelin huonot puolet</h3>
          <ul>
            <li>Kuukausimaksullinen</li>
            <li>Vaatii tehokkaan tietokoneen</li>
            <li>Ei vapaan ajon mahdollisuutta</li>
            <li>Ei moditukea</li>
          </ul>
          <h3>Kenelle peli sopii</h3>
          <ul>
            <li>Kilpaa ajaville, jotka haluavat tavoitella huippuaan</li>
          </ul>
          <img
            id="iRacingLogo"
            src="assets/suositutSimulaattorit/iracing.jpg"
            alt="iracing_logo"
            width="80%"
          />
          <small>Kuva iRacingista ja sen logosta</small>
        </TextContainer>
        <Spacer height="10vh" mobileHeight="50vh" id="simulatorsFourthSpacer" />
        <TextContainer header="Dirt Rally 2.0" id="simulatorsDirtRally">
          <p>
            Dirt Rally 2.0 on suosittu rallisimulaattori, joka tarjoaa erittäin
            realistisen ja haastavan ajokokemuksen. Peli sisältää laajan
            valikoiman ralliautoja, mukaan lukien sekä moderneja että klassisia
            ajoneuvoja, sekä erilaisia ratoja ja ympäristöjä kilpaa varten.
          </p>
          <p>
            Yksi Dirt Rallyn tärkeimmistä myyntivalteista on sen edistyksellinen
            fysiikkamoottori, joka simuloi autojen ja radan käyttäytymistä
            erittäin realistisella tavalla. Tämä tarjoaa pelaajille
            todenmukaisen rallikokemuksen, jossa on tarkka käsittely- ja
            jousituskäyttäytyminen sekä realistiset vauriot ja kuluminen. Samoin
            kuin iRacingissa, vauriot eivät ole pehmeärunkosimulaattorin
            tasoisia.
          </p>
          <p>
            Pelin uratila tarjoaa pelaajille erilaisia rallitapahtumia, kuten
            aika-ajoja, rallia ja mäkikiipeilyä. Dirt Rally tukee myös
            online-moninpeliä, jolloin pelaajat voivat kilpailla toisiaan
            vastaan järjestetyissä tapahtumissa ja liigoissa. Pelin korkea
            vaikeustaso voi olla haastava joillekin pelaajille, mutta se tarjoaa
            myös palkitsevan kokemuksen niille, jotka ovat valmiita käyttämään
            aikaa ja vaivaa pelissä kehittymiseen.
          </p>
          <p>
            Kaiken kaikkiaan Dirt Rally on arvostettu ralli-ajo-simulaattori,
            joka tarjoaa todenmukaisen ja mukaansatempaavan rallikokemuksen
            pelaajille, jotka etsivät realistista ja haastavaa ajopeliä. Peli on
            saatavilla tietokoneelle (Windows), sekä myös PS4:lle ja Xbox
            Onelle.
          </p>
          <h3>Pelin hyvät puolet</h3>
          <ul>
            <li>Realistiset rallifysiikat</li>
            <li>
              Vaurioiden vaikuttaminen ajamiseen ja sen tuomat haasteet kesken
              rallikauden
            </li>
            <li>Uratila</li>
            <li>Sisäänrakennettu VR- ja moninpelituki</li>
          </ul>
          <h3>Pelin huonot puolet</h3>
          <ul>
            <li>Ei avointa maailmaa</li>
            <li>Ei moditukea</li>
          </ul>
          <h3>Kenelle peli sopii</h3>
          <ul>
            <li>
              Rallia ajaville, jotka haluavat realistisen soran simulaation
            </li>
          </ul>
          <img
            id="dirtRallyLogo"
            src="assets/suositutSimulaattorit/dirtrally.jpg"
            alt="dirt_rally_logo"
            width="80%"
          />
          <small>Kuva Dirt Rally 2.0 logosta</small>
        </TextContainer>
        <Spacer height="10vh" mobileHeight="50vh" id="simulatorsFifthSpacer" />
      </ContentWrapper>
    </>
  );
};

export { Simulators };
