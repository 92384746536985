import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Spacer } from "../Spacer";

interface BigTextProps {
  text: string;
  id: string;
  spacing?: string;
}

const BigTextElement = styled.header`
  font-family: "Odibee Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 6rem;
  line-height: 100%;
  align-items: center;
  text-align: center;
  color: #fff;

  text-shadow: 0px 1px 8px rgba(0, 0, 0, 1);

  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
`;

const BigTextContainer = styled.div`
  transform-origin: center center;
  overflow: hidden;
  box-sizing: border-box;
`;

const BigText: React.FC<BigTextProps> = ({ text, id, spacing }) => {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  return (
    <>
      <Spacer height={spacing} id={`${id}BigTextSpacerTop`} />
      <BigTextContainer
        id={`${id}BigTextContainer`}
        style={{
          transform: `scale(${
            scrollPosition
              ? 1 - scrollPosition / 712 > 0
                ? 1 - scrollPosition / 712
                : 0
              : 1
          })`,
        }}
      >
        <BigTextElement id={`${id}BigText`}>{text}</BigTextElement>
      </BigTextContainer>
      <Spacer height={spacing} id={`${id}BigTextSpacerBottom`} />
    </>
  );
};

export { BigText };
