import React from "react";
import { Link } from "react-router-dom";
import {
  BigText,
  ContentWrapper,
  ImageView,
  Spacer,
  TextContainer,
} from "../components";

const Newcomers = () => {
  return (
    <>
      <BigText text="Tulokkaille" id="newcomersIntro" spacing="40vh" />
      <ContentWrapper id="newcomersIntroContent">
        <TextContainer
          header="Olen ensikertalainen, kuinka päästä alkuun?"
          id="newcomersIntro"
        >
          <p>
            Hyvä paikka aloittaa on ensin selvittää, millainen simulaattoriajo
            sinua kiinnostaa. Tämän pohjalta lähdet valitsemaan, mikä
            simulaattori voisi olla tätä varten paras, eri simulaattoreista
            lisää <Link to="/simulators">täällä</Link>.
          </p>
          <p>
            Tutustu simulaattorin sekä eri laitteistojen tarjontaan ja
            ominaisuuksiin huolella ennen kuin sijoitat rahaa mihinkään: olisi
            suuri harmi, jos oma kokemus kärsii vääränlaisen simulaattorin tai
            laitteiston ostamisesta puutteellisen taustoituksen vuoksi.
          </p>
          <p>
            Lähtökohtaisesti ensikertalaisen ei kannata suoraan sijoittaa
            parhaimpaan laitteistoon niiden suuren hinnan vuoksi, mutta myös
            halvimpia settejä kannattaa välttää.
            <br />
            Mainitsen tämän siksi, koska moni aloittaa harrastamisen, mutta
            saattaa menettää mielenkiintonsa harrastamisen hyvin aikaisessa
            vaiheessa, <i>"Ei olekaan minun juttu"</i>.
            <br />
            Mikäli tällainen henkilö on sijoittanut suuria summia
            simulaattoriympäristönsä toteutukseen, on rahallinen menetys
            kyseielle henkilölle aika suuri. Vastaavasti taas halvimman setin
            ostaja saattaa helposti menettää kiinnostuksensa huonon kokemuksen
            takia.
          </p>
          <p>
            <b>Enimmät jaarittelut sikseen, eli asiaan:</b> Ensinnäkin,
            tarvitset tietokoneen tai pelikonsolin, joka pystyy ajamaan
            simracing-ohjelmistoa. Monet simracing-pelit ovat saatavilla PC:lle,
            mutta tarjolla on myös erinomaisia vaihtoehtoja konsoleille, kuten
            PlayStationille tai Xboxille.
            <br />
            Varmistaaksesi, että tietokoneesi tai konsolisi on tehtävänsä
            tasalla, sinun kannattaa tarkistaa haluamasi simracing-ohjelmiston
            järjestelmävaatimukset. Onneksi simracing-pelejä on tarjolla suuri
            liuta, jonka vuoksi tarjolla on pelejä, jotka pyörivät myös
            vanhemmallakin tai vähätehoisemmallakin laittestolla.
          </p>
          <p>
            Jotta kokemus olisi entistä mukaansatempaavampi, kannattaa harkita
            kilpaistuinta tai ohjaamoa ohjauspyörän ja polkimien asentamiseen.
            Tämä auttaa sinua tuntemaan enemmän yhteyttä virtuaaliautoon ja voi
            vaikuttaa merkittävästi suoritustasi radalla.
            <br />
            Lopuksi tarvitset näytön, television tai virtuaalilasit
            simracing-ohjelmiston näyttämiseen. Suurempi näyttö voi auttaa sinua
            näkemään enemmän radasta ja helpottaa muiden autojen tai esteiden
            havaitsemista. Korkea virkistystaajuusmonitori tai televisio voi
            myös auttaa vähentämään liikkeen epäterävyyttä ja tekemään
            kokemuksesta realistisemman. Virtuaalilasit tarjoavat realistisen
            pään liikeen, sekä syvyysnäön, mikäli ohjelmisto tätä tukee, tästä
            lisää myöhemmin.
          </p>
          <p>
            Kaiken kaikkiaan vaikka on mahdollista päästä alkuun pelkällä
            ohjauspyörällä ja poljinsarjalla ja tietokoneella, kilpaistuimeen ja
            näyttöön sijoittaminen voi parantaa simracing-kokemusta
            huomattavasti. Oikeilla asetuksilla jopa täysin aloittelija voi
            alkaa hioa taitojaan ja edetä virtuaalikilpailuissa.
          </p>
        </TextContainer>
        <Spacer height="10vh" mobileHeight="50vh" id="newcomerFirstSpacer" />
        <TextContainer header="Aloittelijalle" id="newcomersNewcomer">
          <p>
            Kun sinulla on tietokone tai konsoli, joka pystyy ajamaan
            simracing-ohjelmistoa, tarvitset ohjauspyörän (eli ratin) ja
            polkimet. Hyviä lähtötason ohjauspyöriä ja poljinsarjoja löytyy noin
            200 eurolla ja ne yksistään tarjoavat jo paljon mukaansatempaavamman
            kokemuksen kuin pelkkä peliohjain tai näppäimistö. Halutessasi voit
            myös tutkia kirpputoreja ja muita käytettyjen tavaroiden
            jälleenmyyntipalveluita (esimerkiksi{" "}
            <a href="https://tori.fi" target="_blank">
              tori.fi
            </a>
            ).
          </p>
          <p>
            Näistä paikoista saattaa joskus tehdä löytöjä, sanon tämän
            kokemuksen pohjalta kertoen: ensimmäinen ohjauspyörä- ja
            poljinsettini on ostettu käytettynä 90 eurolla torista. Kyseessä on
            Logitech G27, vähän vanhempi, rataspohjainen ratti, mutta tuohon
            hintaan ostettuna erittäin hyvä. Settiin kuuluu Force feedbackilla
            varustettu ratti, polkimet, joissa on kaasu, jarru ja kytkin, sekä
            6-vaihteinen vaihdekeppi.
          </p>
          <p>
            Ääripäitä välttämällä ostamalla keskinkertaisen ja
            aloittelijaystävällisen setin, kokemus on tasokas, muttei kuitenkaan
            tökerön hinnakas.
            <br />
            Varsinkin jos kyseessä on pelkästään ratti-polkimet -setti, ovat
            jälleenmyyntimahdollisuudet myös hyvät, mikäli toteatkin, että
            harrastus ei sovi sinulle (tätä en tietenkään toivo).
          </p>
          <h3>Logitech G920</h3>
          <p>
            Moni puhuu, että Logitechin rattisetit eivät ole hyviä
            aloittelijoille, koska parempiakin on olemassa. Itse olen asiasta
            eri mieltä: esimerkiksi Logitechin G920 ei todellakaan ole paras
            setti, mutta kyseisen setin saa noin 200 eurolla, kun taas jos
            haluaa suoraan hypätä korkeamman tason laitteistoon, tulee olla
            valmis sijoittamaan 300 euroa tai enemmän, jotta saat samat
            peruselementit kuin mitä Logitechin kyseinen setti tarjoaa.
          </p>
          <p>
            Tämän takia G920 on erittäin hyvä setti, jos harrastusta haluaa
            kokeilla. Force feedbackin voimakkuus on aika mieto ja ratilla on
            tapana olla äänekäs, mutta se tarjoaa helpon pääsyn päästä
            kokeilemaan simracingia. Jos harrastus tuntuu mielekkäältä, voi
            tässä vaiheessa alkaa harkita parempaan settiin sijoittamista ja
            vanhan myyntiä eteenpäin.
          </p>
          <img
            id="logitechG920Image"
            src="assets/ensikertalainen/logitechg920.png"
            alt="logitech_g920_racing_wheel"
            width="80%"
          />
          <small>
            Yllä on kuva Logitech G920-rattisetistä, joka on monelle
            aloittelijalle hyvä valinta
          </small>
        </TextContainer>
        <ImageView
          id="proTierWheelBase"
          src="assets/ensikertalainen/fanateccsldd.jpg"
          alt="fanatec_direct_drive_wheel"
        />
        <TextContainer header="Kokeneemmalle" id="newcomersExperienced">
          <p>
            Kokeneemmille ja edistyneemmille simracereille hyvän setupin
            merkitys on entistä tärkeämpää sekä suorituskyvyn, että immersion
            maksimointia varten. Laadukas ratti-poljin -setti edistyneellä ja
            tarkalla force feedbackilla ja monipuolisilla säädöillä voi tehdä
            merkittävää eroa niin kierrosaikoihin kuin myös ajon tasaisuuteen.
          </p>
          <p>
            Päivitys Direct Drive (DD lyhyesti) pohjaiseen rattisettiin nostaa
            tarkkuuden ja samalla myös realistisuuden kokonaan uudelle tasolle.
            Lisäksi edistyneen simracerin kannattaa harkita sijoitusta
            korkeamman luokan kilpaistuimeen, kenties jopa liikealustaan tai
            virtuaalilaseihin luodakseen mahdollisimman immersiivisen ja
            realistisen kokemuksen, omasta halustaan ja budjetista riippuen.
          </p>
          <p>
            Myös ohjelmiston asetusten hienosäätö voi auttaa tiristämään
            pienetkin suorituskykyparannukset ulos niin ajajasta kuin myös
            setupista. Näillä eväillä ja omistautumisella, sekä suurella
            määrällä harjoittelua voi edistynyt simracer tähdätä korkeankin
            luokan kilpa-ajamiseen.
          </p>
          <img
            id="simSeat"
            src="assets/ensikertalainen/seat.jpg"
            alt="simracing_seat"
            width="80%"
          />
          <small>
            Yllä on kuva setupista, joka täyttää vakavamminkin ajavan henkilön
            tarpeet
          </small>
        </TextContainer>
        <Spacer height="10vh" mobileHeight="50vh" id="newcomerSecondSpacer" />
        <TextContainer
          header="Virtuaalitodellisuus"
          id="newcomersVirtualReality"
        >
          <p>
            Virtuaalitodellisuus (VR) sopii luonnostaan simracingiin, koska se
            voi parantaa huomattavasti kokemuksen immersiivisyyttä ja
            realistisuutta.
            <br />
            VR-kuulokkeet, kuten Oculus Quest 2 tai Valve Index, tarjoavat 360
            asteen näkymän virtuaalimaailmaan, jolloin voit katsoa ympärillesi
            ohjaamossa ja seurata samalla tavalla kuin oikeassa elämässä.
            Lisätty syvyysnäkö ja tilatietoisuus voivat auttaa sinua reagoimaan
            nopeammin radalla tapahtuviin asioihin ja tekemään tarkempia
            ohjausliikkeitä.
          </p>
          <p>
            Jotta voit hyödyntää täysin VR:ää simracingissa, tarvitset tehokkaan
            tietokoneen, jossa on nopea näytönohjain ja runsaasti RAM-muistia.
            Haluat myös huippuluokan VR-kuulokkeet korkealla resoluutiolla ja
            virkistystaajuudella parhaan mahdollisen visuaalisen kokemuksen
            saavuttamiseksi. Halvemman tason headsettejäkin on, jotka hoitavat
            homman hyvin, esimerkiksi Oculus Quest 2.
          </p>
          <p>
            Yhdistääksesi VR:n olemassa olevaan simsetuppiin, sinun
            käyttöönotettava headset ja sen jälkeen konfiguroida se osaksi
            sim-ohjlemistossa käytettävää laitteistoa. Monissa
            simracing-peleissä on onneksi sisäänrakennettu VR-tuki, mutta saatat
            joutua säätämään asetuksia ja kalibroimaan laitteiston sujuvan ja
            mukavan pelikokemuksen varmistamiseksi. Joihinkin peleihin VR:tuen
            saa modien avulla. Oikeilla varusteilla ja asetuksilla VR voi viedä
            simracing-kokemuksesi uudelle tasolle.
          </p>
          <img
            id="vrHeadset"
            src="assets/ensikertalainen/quest2.jpg"
            alt="oculus_quest_2_vr_headset"
            width="80%"
          />
          <small>
            Yllä markkinointikuva Metan "Quest 2" -virtuaalilaseista
          </small>
        </TextContainer>
        <ImageView
          id="thrustMasterImage"
          src="assets/ensikertalainen/thrustmastert300rs.jpg"
          alt="thrustmastert300rs_racing_wheel"
        />
      </ContentWrapper>
    </>
  );
};

export { Newcomers };
