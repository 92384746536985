import React from "react";
import styled from "styled-components";

const SpacerElement = styled.div`
  padding-top: ${(props: SpacerProps) =>
    props.mobileHeight || props.height || "0"};
  padding-right: ${(props: SpacerProps) =>
    props.mobileWidth || props.width || "0"};

  @media only screen and (min-width: 768px) {
    padding-top: ${(props: SpacerProps) => (props.height ? props.height : "0")};
    padding-right: ${(props: SpacerProps) => (props.width ? props.width : "0")};
  }
`;

interface SpacerProps {
  width?: string;
  height?: string;
  mobileWidth?: string;
  mobileHeight?: string;
  id: string;
}

const Spacer = (props: SpacerProps) => {
  const { width, height, mobileWidth, mobileHeight, id } = props;
  return (
    <SpacerElement
      width={width}
      height={height}
      mobileWidth={mobileWidth}
      mobileHeight={mobileHeight}
      id={id}
      unselectable="on"
    />
  );
};

export { Spacer };
