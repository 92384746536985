import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 768px) {
    margin: auto;
    backdrop-filter: blur(12rem);
    background-color: rgba(15, 15, 15, 0.75);
  }
`;

interface ContentWrapperProps {
  children?: React.ReactNode;
  id: string;
}

const ContentWrapper: React.FC<ContentWrapperProps> = ({ children, id }) => {
  return <Wrapper id={id}>{children}</Wrapper>;
};

export { ContentWrapper };
