import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import "./App.css";
import { Header, Footer, BackgroundVideo, BackToTop } from "./components";
import { Introduction, Modes, Newcomers, Simulators } from "./pages";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    // "document.documentElement.scrollTo" is the magic for React Router Dom v6
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto", // Optional if you want to skip the scrolling animation
    });
  }, [pathname]);

  return null;
};

const App = () => {
  return (
    <BrowserRouter>
      <Header />
      <ScrollToTop />
      <Routes>
        <Route path="/">
          <Route index element={<Introduction />} />
          <Route path="modes" element={<Modes />} />
          <Route path="newcomers" element={<Newcomers />} />
          <Route path="simulators" element={<Simulators />} />
          <Route path="*" element={<Introduction />} /> // Fallback
        </Route>
      </Routes>
      <Footer />
      <BackToTop />
      <BackgroundVideo />
    </BrowserRouter>
  );
};

export default App;
