import React from "react";
import styled from "styled-components";

const Root = styled.footer`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 1.2rem;
  box-sizing: border-box;

  height: 15rem;

  background: #000000;
  z-index: 900;

  & a {
    color: #ffffff;
  }
`;

const BoldText = styled.p`
  font-family: "Passion One";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 100%;

  text-align: center;
  letter-spacing: 0.02em;

  color: #ffffff;
`;

const Text = styled.p`
  font-family: "Pavanam";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 100%;

  text-align: center;
  letter-spacing: 0.02em;

  color: #ffffff;
`;

const Footer: React.FC = () => {
  return (
    <Root id="footerRoot">
      <BoldText id="footerTitle">SimRacingFans</BoldText>
      <Text id="footerContactInfo">
        niko.huuskonen@edu.karelia.fi
        <br />
        +358 457 834 4723
        <br />
        <br />
        Käytetyn musiikin tekijä{" "}
        <a
          href="https://pixabay.com/users/evgeny_bardyuzha-25235210/?utm_source=link-attribution&utm_medium=referral&utm_campaign=music&utm_content=123276"
          target="_blank"
        >
          Evgeny_Bardyuzha
        </a>{" "}
        <a
          href="https://pixabay.com/music//?utm_source=link-attribution&utm_medium=referral&utm_campaign=music&utm_content=123276"
          target="_blank"
        >
          Pixabaysta
        </a>
      </Text>
      <BoldText id="footerCopyright">
        Copyright ©{" "}
        <a href="https://n1ko23.moe" target="_blank">
          Niko Huuskonen
        </a>{" "}
        {new Date().getFullYear()}
      </BoldText>
    </Root>
  );
};

export { Footer };
