import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 1rem;
  box-sizing: border-box;

  background: #e4dddb;
  border-radius: 1rem;

  margin-top: -1rem;
  margin-bottom: -1rem;

  color: #000000;
  z-index: 490;

  @media only screen and (min-width: 768px) {
    width: 80%;
    margin: 0;
    border-radius: 0;
    box-shadow: 0 1rem 1rem 1rem rgba(0, 0, 0, 0.2);
  }

  @media only screen and (min-width: 1280px) {
    width: 60%;
  }

  @media only screen and (min-width: 2048px) {
    width: 40%;
  }
`;

const Image = styled.img`
  display: flex;
  object-fit: cover;
  width: 100%;
  height: 100%;
  color: #000000;
`;

interface ImageViewProps {
  src: string;
  alt: string;
  id: string;
}

const ImageView: React.FC<ImageViewProps> = ({ src, alt, id }) => {
  return (
    <Container id={`${id}ImageViewContainer`}>
      <Image id={`${id}ImageView`} src={src} alt={alt} />
    </Container>
  );
};

export { ImageView };
