import React from "react";
import styled from "styled-components";
import { ReactComponent as HamburgerIcon } from "./lines.svg";

interface HamburgerMenuProps {
  onClick?: (event: React.MouseEvent) => void;
  isActive?: boolean;
  children?: React.ReactNode;
  id: string;
}

const HamburgerButton = styled.button`
  border: 0.2rem solid rgba(0, 0, 0, 0.8);
  border-radius: 0.8rem;
  filter: drop-shadow(0px 0px 4px rgba(255, 255, 255, 0.75));
  box-shadow: 0px 0px 4px rgba(255, 255, 255, 0.5);
  background: none;
  padding: 0.4rem;
  cursor: pointer;

  z-index: 1001;
  transition: background 0.2s;

  &:hover {
    background: rgba(250, 250, 250, 0.25);
  }

  &:active {
    background: rgba(255, 255, 255, 1);
  }

  &.active {
    background: rgba(250, 250, 250, 0.5);
  }
`;

const HamburgerMenu: React.FC<HamburgerMenuProps> = ({
  onClick,
  isActive,
  children,
  id,
}) => {
  // let rows = [];
  // for (let i = 0; i < fields.length; i++) {
  //   rows.push(
  //     <li className="nav-item" key={fields[i]}>
  //       <a className="nav-link" href={"#" + fields[i]}>
  //         {fields[i]}
  //       </a>
  //     </li>
  //   );
  // }

  return (
    <>
      <HamburgerButton
        type="button"
        onClick={onClick}
        aria-expanded="false"
        aria-label="Toggle navigation"
        id={`${id}HamburgerMenuButton`}
        className={isActive ? "active" : ""}
      >
        <HamburgerIcon id={`${id}HamburgerMenuButtonIcon`} />
      </HamburgerButton>
      {children}
    </>
  );
};

export { HamburgerMenu };
