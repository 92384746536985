import React from "react";
import styled from "styled-components";

const BGVideo = styled.video`
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -100;

  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
`;

const BackgroundVideo: React.FC = () => {
  return (
    <>
      <BGVideo autoPlay muted loop playsInline id="backgroundVideo">
        <source src="assets/taustavideo.mp4" type="video/mp4" />
      </BGVideo>
    </>
  );
};

export { BackgroundVideo };
