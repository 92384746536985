import React, { useEffect, useState } from "react";
import styles from "styled-components";
import { ReactComponent as Arrow } from "./arrow.svg";

const BackToTopButton = styles.button`
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  z-index: 900;
  padding: 1rem;

  background-color: #000;
  border: 2px solid rgba(255, 255, 255, 0.5);
  border-radius: 0.6rem;

  transition: all 0.2s;
  transform: translateX(6rem);

  &.visible {
    transform: translateX(0);
  }
  &:hover {
    background-color: #0f0f0f;
  }
  &:active {
    background-color: #f0f0f0;
    transform: scale(0.8)
  }
`;

const BackToTop: React.FC = () => {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  const handleClick = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const isVisible = scrollPosition > 128;

  return (
    <BackToTopButton
      className={isVisible ? "visible" : ""}
      tabIndex={isVisible ? 0 : -1}
      onClick={handleClick}
      id="backToTopButton"
    >
      <Arrow />
    </BackToTopButton>
  );
};

export { BackToTop };
