import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 2rem;
  gap: 1rem;
  box-sizing: border-box;

  background: #e4dddb;
  border-radius: 1rem;

  font-family: "Overlock SC";
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 120%;

  margin-top: -1rem;
  margin-bottom: -1rem;

  color: #000000;
  z-index: 500;

  @media only screen and (min-width: 768px) {
    width: 80%;
    margin: 0;
    border-radius: 0;
    padding: 2rem 3rem;
    box-shadow: 0 1rem 1rem 1rem rgba(0, 0, 0, 0.2);
  }

  @media only screen and (min-width: 1280px) {
    width: 60%;
    padding: 2rem 4rem;
  }

  @media only screen and (min-width: 2048px) {
    width: 40%;
    padding: 2rem 6rem;
  }

  & small {
    text-align: center;
  }

  & ul {
    width: 100%;
  }

  & img {
    border-radius: 0.2rem;
  }
`;

const Header = styled.div`
  font-family: "PT Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 2rem;
  line-height: 200%;

  display: flex;
  align-items: center;
  text-align: center;

  color: #000000;
`;

interface TextContainerProps {
  header: string;
  children?: React.ReactNode;
  id: string;
}

const TextContainer = (props: TextContainerProps) => {
  const { header, children, id } = props;

  return (
    <Container id={`${id}TextContainer`}>
      <Header id={`${id}Header`}>{header}</Header>
      {children}
    </Container>
  );
};

export { TextContainer };
