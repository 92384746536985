import React from "react";
import {
  BigText,
  ContentWrapper,
  ImageView,
  TextContainer,
} from "../components";

const Introduction = () => {
  return (
    <>
      <BigText text="Tervetuloa" id="tervetuloa" spacing="40vh" />
      <ContentWrapper id="introductionContent">
        <TextContainer header="Johdanto" id="introduction">
          <p id="introductionText">
            SimRacingFans on fanisivu simulaattoriajolle, sitä harrastaville tai
            muuten vain aiheesta kiinnostuneille. Tämä verkkosivu tarjoaa
            perehdytyksen siihen, mitä simracing on, mitkä ovat hyviä
            simulaattoreita ja mitä kalustoa harrastamiseen kannattaa omistaa.
            Kullekin näistä osioista löytyvät omat alasivut.
          </p>
          <p>
            Sivustolla navigointi tapahtuu pääasiassa oikeasta yläkulmasta
            löytyvän hampurilaisvalikon kautta. Valikko avaa listan verkkosivun
            alasivuista ja linkkejä klikkaamalla pääsee kullekin alasivulle.
            <br />
            Sivua alaspäin rullatessa ilmestyy oikeaan alalaitaan nappi, jota
            klikkaamalla pääsee takaisin verkkosivun ylälaitaan. Verkkosivun
            otsikon klikkaaminen palauttaa sinut takaisin aloitussivulle.
            <br />
            Pienenä bonuksena sivun vasemmasta ylälaidasta löytyvää rattia
            klikkaamalla saat soimaan copyright-vapaata taustamusiikkia, ratin
            klikkaaminen uudelleen pysäyttää musiikin.
          </p>
          <p>
            Sivusto on toteutettu osana Karelian ammattikorkeakoulun
            WebMob-kurssia. Aiheen valinta verkkosivuille oli vapaa, samoin kuin
            myös käytettävä rajapinta ja toteutustapa. Verkkosivun päätavoite on
            olla hyvin käytettävä ja saavutettava eri alustoilla.
          </p>
          <p>
            Valitsin tälle projektille rajapinnaksi React-frameworkin ja
            komponenttien toteuttamisessa hyödynnän styled-components -moduulia.
          </p>
        </TextContainer>
        <ImageView
          id="introSimSetup"
          src="assets/johdanto/setup.jpg"
          alt="sleek_sim_setup"
        />
        <TextContainer header="Mitä on simracing?" id="whatIsSimracing">
          <p>
            Simracingista eli virtuaalisesta kilpailusta on tullut yhä
            suositumpi tapa kokea moottoriurheilun jännitystä ja haasteita
            mukavasti omassa kodissaan.
          </p>
          <p>
            Päätavoite simracingilla on tähdätä mahdollisimman todenmukaiseen
            ajokokemukseen. Tämän vuoksi simulaattorissa fysiikat tähtäävät
            todenmukaisuuteen ja otetaan huomioon mahdollisimman monet eri
            muuttujat, jotka tulee huomioida oikeassa ajamisessa, kuten
            renkaiden ja polttoaineen kuluminen, sään vaikutus ajoon ja
            näkyvyyteen, auton säädöt, sekä myös autoon tuleva vahinko
            esimerkiksi törmätessä.
          </p>
          <p>
            Simulaattoriajoa harjoitetaan usein tietokoneella ja siihen
            tarvitaan ainakin yksi näyttö (tai VR-lasit), sekä ratti ja
            polkimet. Vaihdekepin, käsijarrun ja kunnollisen telineen sekä
            istuimen omistaminen ovat myös merkittävä etu sekä ajotuntuman,
            ergonomian, että todentuntuisuuden vuoksi.
          </p>
        </TextContainer>
        <ImageView
          id="introRacing"
          src="assets/johdanto/racing.png"
          alt="racing_shot"
        />
      </ContentWrapper>
    </>
  );
};

export { Introduction };
