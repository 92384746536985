import React from "react";
import {
  BigText,
  ContentWrapper,
  ImageView,
  Spacer,
  TextContainer,
} from "../components";

const Modes = () => {
  return (
    <>
      <BigText text="Ajomuodot" id="modesIntroBig" spacing="40vh" />
      <ContentWrapper id="modesIntroContent">
        <TextContainer header="Eri ajomuodot" id="modesIntro">
          <p>
            Simulaattoriajoa on monenlaista. Sinun ei tarvitse keskittyä vain
            yhdenlaiseen simulaattoriajoon, mutta jos haluat tähdätä
            kilpailulliseen ajamiseen, kannattaa pääfocus keskittää yhteen tai
            kahteen ajotyyliin.
          </p>
          <p>
            Kilpailulliselta puolelta löytyy rata-ajon eri muodot, ralli, sekä
            driftaus. Kasuaalina voi näiden lisäksi harjoittaa myös
            kaupunkiajoa, kruisailua ja romurallia. Alla kustakin ajotyylistä
            yksityiskohtaisemmin.
          </p>
        </TextContainer>
        <Spacer height="10vh" mobileHeight="50vh" id="modesFirstSpacer" />

        <TextContainer header="Rata-ajo" id="modesTrack">
          <p>
            Rata-ajo simracingissa sisältää virtuaalisen auton ajamisen
            virtuaalisella kilparadalla erittäin realistisessa ja tarkassa
            todellisen ajon simulaatiossa.
          </p>
          <p>
            Rata-ajon tavoitteena simracingissa on saavuttaa mahdollisimman
            nopeimmat kierrosajat, samalla kun säilytetään hallinta ja pysytään
            radan rajoissa. Rata-ajossa kiinnitetään myös huolellista huomiota
            yksityiskohtiin, mukaan lukien auton asettaminen optimaaliseen
            suorituskykyyn ja ajotekniikoiden säätäminen erilaisiin radan
            olosuhteisiin ja ympäristöihin.
          </p>
          <p>
            Kaiken kaikkiaan rata-ajo simracingissa tarjoaa erittäin
            mukaansatempaavan ja realistisen ajokokemuksen, joka on suosittu
            sekä amatööri- että ammattikuljettajien keskuudessa, jotka haluavat
            hioa taitojaan ja kilpailla muita pelaajia vastaan virtuaalisessa
            moottoriurheilussa.
          </p>
          <h3>Ajotyyliin hyvät simulaattorit</h3>
          <ul>
            <li>Assetto Corsa</li>
            <li>iRacing</li>
            <li>Gran Turismo</li>
            <li>Forza Motorsport</li>
          </ul>
          <img
            id="trackDrivingImage"
            src="assets/ajomuodot/trackdriving.jpg"
            alt="still_shot_track_driving"
            width="80%"
          />
          <small>Tilannekuva rata-ajosta (Gran Turismo)</small>
        </TextContainer>
        <ImageView
          id="pitsImage"
          src="assets/ajomuodot/pits.jpg"
          alt="pits_drone_shot"
        />
        <TextContainer header="Ralli" id="modesRally">
          <p>
            Ralli-ajo simracingissa sisältää virtuaalisen auton navigoimisen
            erilaisten maasto- ja olosuhteiden läpi, mukaan lukien sora, lika,
            lumi ja mutaa. Kuljettajat voivat harjoitella ja hioa
            ralliajotaitojaan turvallisessa ja kontrolloidussa ympäristössä
            ilman loukkaantumisvaaraa tai todellisten ajoneuvojen
            vahingoittumista.
          </p>
          <p>
            Ralli-ajossa pelaajat voivat valita laajasta valikoimasta
            ralliautoja, mukaan lukien sekä moderneja että klassisia ajoneuvoja,
            vetotavan ja ajoneuvoryhmän mukaan lukien ja kilpailla erilaisilla
            radoilla ja ympäristöissä, jotka simuloivat todellisia rallikokeita.
            Simracing-ralliajon tavoitteena on saavuttaa nopein kokonaisaika
            useilla eri osilla, samalla kun säilytetään hallinta ja vältetään
            esteitä, vaaroja ja auton vaurioittamista. Autoon tehdään myös
            ratakohtaiset säädöt radan ja olosuhteiden mukaisesti.
          </p>
          <p>
            Kaiken kaikkiaan ralliajo simracingissa tarjoaa erittäin
            mukaansatempaavan ja haastavan ajokokemuksen, joka on suosittu sekä
            amatööri- että ammattiralliajajien keskuudessa.
          </p>
          <h3>Ajotyyliin hyvät simulaattorit</h3>
          <ul>
            <li>Dirt Rally 2.0</li>
            <li>Richard Burns Rally</li>
            <li>WRC 11</li>
          </ul>
          <img
            id="rallyDrivingImage"
            src="assets/ajomuodot/ralli.png"
            alt="subaru_driving_rally"
            width="80%"
          />
          <small>Subaru WRX STi ajamassa rallia (Dirt Rally 2.0)</small>
        </TextContainer>
        <ImageView
          id="pitsImage"
          src="assets/ajomuodot/assettocorsa3.jpg"
          alt="pits_drone_shot"
        />
        <TextContainer header="Drftaus" id="modesDrift">
          <p>
            Driftaaminen simracingissa on ainutlaatuinen ja jännittävä
            ajokokemus, jonka avulla pelaajat voivat kehittää ohjattujen
            liukujen ja luistojen taitoa. Driftaaminen tarkoittaa auton
            tahallista yliohjausta ja luiston ylläpitämistä käännöksen läpi,
            samalla kun säilytetään hallinta ja pysytään radan rajojen sisällä.
          </p>
          <p>
            Simdriftauksessa pelaajat voivat valita laajasta valikoimasta
            driftiautoja, mukaan lukien muunnetut katuautot ja tarkoitukseen
            rakennetut drifterit, ja kilpailla erilaisilla radoilla sekä
            ympäristöissä, jotka simuloivat todellisia drift-tapahtumia. Myös
            katudriftaamista voi harjoittaa simulaattorissa, koska siellä se ei
            aiheuta vaaraa kenellekään, toisin kuin oikeassa elämässä (lisäksi
            kaduilla driftaaminen on rangaistava rike).
          </p>
          <p>
            Driftaamisen tavoitteena on saavuttaa korkeimmat mahdolliset pisteet
            pitämällä yllä pitkiä ja hallittuja liukuja ja yhdistämällä samalla
            useita käännöksiä ja siirtymiä. Simdriftaus vaatii myös huolellisen
            keskittymisen yksityiskohtiin, mukaan lukien auton jousituksen ja
            rengaspaineiden virittämisen optimaalisen pidon ja liukumisen
            takaamiseksi sekä ajotekniikoiden säätämisen erilaisiin radan
            olosuhteisiin ja ympäristöihin.
          </p>
          <p>
            Kaiken kaikkiaan driftaaminen simulaattorissa tarjoaa erittäin
            mukaansatempaavan ja jännittävän ajokokemuksen, joka on suosittu
            sekä amatööri- että ammattidraiffaajien keskuudessa, jotka haluavat
            hioa taitojaan ja kilpailla muita pelaajia vastaan virtuaalisessa
            moottoriurheilussa.
          </p>
          <h3>Ajotyyliin hyvät simulaattorit</h3>
          <ul>
            <li>Assetto Corsa</li>
            <li>BeamNG.drive</li>
            <li>Gran Turismo</li>
            <li>CarX Drift Racing Online</li>
          </ul>
          <img
            id="driftingImage"
            src="assets/ajomuodot/drift.jpg"
            alt="tandem_drifting_cars"
            width="80%"
          />
          <small>
            Dronen tilannekuva Tandem-driftaavista autoista (Assetto Corsa)
          </small>
        </TextContainer>
        <ImageView
          id="winterDriftImage"
          src="assets/ajomuodot/winterdrift.jpg"
          alt="street_drifting_winter"
        />
        <TextContainer header="Muu ajo" id="modesOther">
          <p>
            Vapaa ajelu (freeroaming) ja kruisailu simracingissa ovat
            virtuaaliympäristöjen tutkimista ja niissä ajamista vapaana ilman
            paineita kilpailla muita pelaajia vastaan tai saavuttaa tiettyjä
            tavoitteita. Simracingissa freeroaming ja kruisailu voivat tapahtua
            erilaisissa ympäristöissä, kuten kaupungeissa, maaseudulla ja
            vuoristoteillä.
          </p>
          <p>
            Pelaajat voivat valita laajasta valikoimasta autoja urheiluautoista
            ja superautoista klassisiin autoihin tai jopa kuorma-autoihin, ja he
            voivat muokata ja säätää ajoneuvojaan mielensä mukaan. Freeroamingin
            ja kruisailun tavoitteena simracingissa on yksinkertaisesti nauttia
            ajamisesta ja tutkimisesta ilman perinteisten kilpailusääntöjen ja
            määräysten rajoituksia.
          </p>
          <p>
            Freeroaming ja kruisailu simracingissa voivat myös tarjota erittäin
            mukaansatempaavan ja realistisen ajokokemuksen, jolloin pelaajat
            voivat tuntea, että he todella ajavat eri ympäristöissä ja
            maisemissa. Ajoa voi tehdä sekä yksin, että moninpelissä. Joskus
            järjestetään jopa virtuaalisia automiittejä erilaisissa paikoissa,
            kuten ison kaupungin parkkipaikalla yöaikaan.
          </p>
          <p>
            Kaiken kaikkiaan freeroaming ja kruisailu simracingissa tarjoavat
            ainutlaatuisen ja nautinnollisen ajokokemuksen, joka on suosittu
            pelaajien keskuudessa, jotka haluavat rentoutua ja tutkia
            virtuaalimaailmoja omaan tahtiinsa.
          </p>
          <h3>Ajotyyliin hyvät simulaattorit</h3>
          <ul>
            <li>Assetto Corsa</li>
            <li>BeamNG.drive</li>
            <li>Forza Horizon 5</li>
          </ul>
          <img
            id="cruiseImage"
            src="assets/ajomuodot/cruise.jpg"
            alt="cars_at_parking_lot"
            width="80%"
          />
          <small>Tilannekuva autoista parkkipaikalla (Assetto Corsa)</small>
        </TextContainer>
        <ImageView
          id="derbyImage"
          src="assets/ajomuodot/derby.png"
          alt="derby_cars_ready_to_go"
        />
      </ContentWrapper>
    </>
  );
};

export { Modes };
