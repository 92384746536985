import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { NavigationDropdownElement } from "./NavigationDropdownElement";
import { pages } from "../../pages";

interface NavigationDropdownProps {
  isVisible: boolean;
  id: string;
  onClose?: () => unknown;
}

const NavigationDropdownComponent = styled.div`
  display: flex;
  position: fixed;
  top: ${(props: Partial<NavigationDropdownProps>) =>
    props.isVisible ? "0" : "-16rem"};
  right: 0;
  flex-direction: column;
  align-items: flex-start;
  padding: 4.4rem 0 0.4rem 0;
  border-radius: 0px 0px 1rem 1rem;
  border: 0.1rem solid rgba(255, 255, 255, 0.5);
  gap: 0.2rem;

  width: 100%;
  box-sizing: border-box;
  overflow: hidden;

  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(1rem);
  z-index: 900;

  transition: top 0.2s;

  @media only screen and (min-width: 768px) {
    width: 32rem;
  }
`;

const NavigationDropdown: React.FC<NavigationDropdownProps> = ({
  id,
  isVisible,
  onClose,
}) => {
  const rootRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      rootRef.current &&
        !rootRef.current.contains(event.target as Node) &&
        onClose &&
        isVisible &&
        setTimeout(() => onClose(), 100);
    };

    document.addEventListener("onblur", handleClickOutside);
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("onblur", handleClickOutside);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [rootRef, onClose]);

  return (
    <NavigationDropdownComponent
      isVisible={isVisible}
      ref={rootRef}
      id={`${id}NavigationDropdown`}
    >
      {isVisible &&
        pages.map((page) => (
          <NavigationDropdownElement
            element={{ text: page.text, path: page.path }}
            id={`${page.text}NavigationDropdownElement`}
            key={Math.random()}
          ></NavigationDropdownElement>
        ))}
    </NavigationDropdownComponent>
  );
};

export { NavigationDropdown, NavigationDropdownElement };
