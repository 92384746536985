import { NavigationDropdownElementType } from "../components/NavigationDropdown/NavigationDropdownElement";

const pages: NavigationDropdownElementType[] = [
  {
    path: "/",
    text: "Johdanto",
  },
  {
    path: "/newcomers",
    text: "Tulokkaille",
  },
  {
    path: "/simulators",
    text: "Simulaattorit",
  },
  {
    path: "/modes",
    text: "Ajomuodot",
  },
];
export * from "./introduction";
export * from "./modes";
export * from "./newcomers";
export * from "./simulators";
export { pages };
