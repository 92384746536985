import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";

export type NavigationDropdownElementType = {
  text: string;
  path: string;
};

interface NavigationDropdownElementProps {
  element: NavigationDropdownElementType;
  id: string;
}

const NavigationDropdownElementComponent = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem 1rem;
  gap: 0.5rem;

  width: 100%;
  height: 1.5rem;

  background: none;

  font-family: "Play";
  font-style: normal;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 0%;

  display: flex;
  align-items: center;

  color: #f4f4f4;
  text-decoration: none;
  transition: background 0.2s;

  &.active {
    background: rgba(255, 255, 255, 0.2);
  }

  &:hover {
    background: rgba(255, 255, 255, 0.4);
  }

  &:active {
    transition: background 0.1s;
    background: rgba(255, 255, 255, 0.75);
  }
`;

const Dot = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 0.75rem;
  background-color: #f4f4f4;
`;

const NavigationDropdownElement: React.FC<NavigationDropdownElementProps> = ({
  element,
  id,
}) => {
  const location = useLocation();
  return (
    <NavigationDropdownElementComponent
      to={element.path}
      className={location.pathname == element.path ? "active" : ""}
      id={`${id}NavigationDropdownElement`}
      key={id}
    >
      <Dot></Dot>
      {element.text}
    </NavigationDropdownElementComponent>
  );
};

export { NavigationDropdownElement };
